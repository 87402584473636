.Main-heading{
    color: #4F4F4F;
font-family: Montserrat;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;

}

input.form-control.complaint-Name::placeholder{
    color: #d18b52;
}

/* .complaint-select{
    height: 80px;
} */

div.css-13cymwt-control{
    height: 38.4px;
}

.save-button{
    display: flex;
    color: white;
    width: 180px;
    height: 52px;
    padding: 17px 16px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    border-color: #006AB0;
    box-shadow: none;
    background: #006AB0;
}

.main-div{
    height: 72vh;
}