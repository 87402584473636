.custom-scrollbar{
    background-color: #CDD4E6;
}

.sider-nav {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(110vh - 0px);
    min-height: calc(100vh - 0px);
    position: relative;
    left: 0%;
    top: 0%;
    bottom: 0%;
    background-color: #006AB0;
    border-radius: 20px 20px 0px 0px;
}

.sider-menu {
    color: #FFF;
    background-color: #006AB0;
    overflow: auto;
}

.icons {
    color: #FFF !important;
}

.content-card {
    height: 60vh;
    width: 60vw;
}

.mainContent {
    background-image: url('../../../public/images/Rectangle5.jpg');
    background-color: #006AB0;
    margin-bottom: none;

}

li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected {
    color: #051a7a !important;
    font-weight: 700;
    /* background-image: linear-gradient(to right, #54b4f3, #006AB0); */

}

li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    /* color:rgb(5, 26, 122); */
    font-weight: 600;
}

/* when link is active below is the class selected */
.ant-menu-item-selected {
    /* border: 3px solid #C7D5F8; */
    background: linear-gradient(90deg, #FFF 7.39%, #97bdd7 92.44%);
    /* background-image: linear-gradient(to right, #54b4f3, #006AB0); */
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3 ); */
    cursor: pointer;
    margin: 0;
    /* padding: 10px; */
    /* color:#006AB0; */
    /* border-radius: 5px; */
    font-weight: 600;
}



.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    padding-inline: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline: 0px;
    margin-block: 0px;
    width: calc(100%);

}


.ant-menu .ant-menu-item,
.ant-menu .ant-menu-submenu,
.ant-menu .ant-menu-submenu-title {
    border-radius: 0px;
    font-size: 15px;
    /* padding-left: 25px; */
}

.icons {
    padding: 0 13px;
    /* color: aliceblue; */
}

.ant-menu-sub {
    background: linear-gradient(90deg, #9eafd8 2.58%, #ced3df 50.57%, #d5d0d0 98.77%) !important;
    font-weight: 600;

}

.ant-menu-item-selected a {
    color: white;
    font-weight: 600;

}

.ham-burger {
    display: none;
}

li.ant-menu-item.ant-menu-item-only-child {

    /* border:1px solid #006AB0; */
    font-size: 13px;
    color: #006AB0;
    /* padding-left: 90px !important; */

}

li.ant-menu-item.ant-menu-item-only-child:hover {
    background-color: #99dcfb !important;
    color: #080808;
}

.ant-menu-item.ant-menu-item-only-child {
    /* margin-left:50px; */
    display: flex;
    align-items: end;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.css-dev-only-do-not-override-2i2tap.ant-menu-submenu-placement-rightTop{
   display: none;
}



@media only screen and (max-width:480px) {
    .layout-sider {
        display: none;
    }

    .ham-burger {
        display: block;
    }

    /* .open-sider{
        display:block;
    } */
}

.open-sider {
    display: block;
}


.ant-layout .ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #006AB0;
    /* transition: all 0.2s,background 0s; */
}


/* Customize scroll bars */
::-webkit-scrollbar {
    width: 7px;
    height: 10px;
}


::-webkit-scrollbar-thumb {
    background-color: #b4b3b3;
    /* Color of the scrollbar thumb */
    border-radius: 6px;
    /* Radius of the scrollbar thumb */
    width: 2px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Color of the scrollbar track */

}


div.ant-layout-sider-trigger {
    background: #006AB0 !important;

}

.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
.ant-menu-light>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: #051a7a;
    font-weight: 600;
    /* background-color: ; */
}

div.ant-layout-sider-trigger svg {
    color: white;
    border-radius: 50%;
    /* border: 2px solid black; */
    height: 15px;
    width: 20px;
}

.leftnavmenu-Icon{
    display:flex;
    margin-right:0px ;
    position: absolute;
    right: 30px;
   
}

.smallscreen-kidlogo{
    display:flex;
    position: relative;
    right: 0px;
}

/* .searchBar{
    border: 16px;
    padding: 14px 24px;
    width: 800px;
    height: 60px;
} */

/* Default styles */
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #d9d9d9;
    background: #ffffff;
    width: 45rem; /* Default width for larger screens */
}

/* Media query for smaller screens including tablets and PCs */
@media (min-width: 577px) and (max-width: 768px){
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%; /* Set width to 100% for smaller screens */ 
    }
}
@media (min-width: 769px) and (max-width: 992px) {
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%; /* Set width to 100% for smaller screens */
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%; /* Set width to 100% for smaller screens */
    }
}