.uploadDoc {
    position: relative;
    display: flex;
    width: 210px;
    height: 38px;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media (min-width: 200px) and (max-width: 480px) {
    .uploadDoc{
        width:100%; /* Adjust the width for screens between 200px and 480px */
        height: 5vh;
        font-size:11px;
  
    }
  }
  
  
  
  @media (min-width: 481px) and (max-width: 576px) {
    .uploadDoc{
        width: 100%; /* Adjust the width for screens wider than 576px */
        height: 6vh;
        font-size:13px;
        
        /* line-height:1px; */
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .uploadDoc{
        width: 100%; /* Adjust the width for screens wider than 768px */
     
        /* line-height:3px; */
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px){
    .uploadDoc{
    
        width: 100%; /* Adjust the width for screens wider than 992px */
    }
  }
  
  @media (min-width: 993px) and (max-width: 1200px){
    .uploadDoc{
        width: 100%; /* Adjust the width for screens wider than 1200px */
        /* height: 38px; */
    }
  }