.login_bgImg {

  background-image: url('../../../public/images/Login_page_1.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;


}

.login {
  background-color: rgba(255, 255, 255, 0.0);
  /* padding: 100px 100px; */
  /* width: 510px; */
  height: 500px;
  border-radius: 64px;
  width: 20%;
  /* max-width: 510px; */
  max-width: 460px;
  text-align: center;
  /* height: 80%;  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8%;
  position: relative;
}

.logo_image {
  /* width: 289px;
  height: 114px;  */
  position: absolute;
  top: 10px;
  /* Adjust the top position to move it to the top of the login box */
  left: 50%;
  /* Center the image horizontally */
  transform: translateX(-50%);
  width: 100%;
  max-width: 289px;
  height: auto;
}

.login-button {
  display: flex;
  height: 46px;
  width: 100%;
  max-width: 800px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: #006AB0;
  /* margin-top: 50px; */
}

.input-outline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  /* Adjust the thickness of the bottom border as needed */
  background-color: #A7A7A7;
  /* Color of the bottom border */
}

.was-validated .form-control:invalid:not(:focus),
.was-validated .form-control:valid:not(:focus) {
  background-image: none;
  /* border-color: red; */
}

.was-validated .form-control:focus:invalid,
.was-validated .form-control:focus:valid {
  background-image: none;
  box-shadow: none;
}

.was-validated .form-control:invalid:not(:focus),
.was-validated .form-control:valid:not(:focus),
.was-validated .form-control:focus:invalid,
.was-validated .form-control:focus:valid,
.was-validated select:invalid:not(:focus),
.was-validated select:valid:not(:focus),
.was-validated select:focus:invalid,
input.input-style.form-control.is-invalid,
textarea.Description_field.form-control.is-invalid,
.was-validated select:focus:valid {
  background-image: none;
  box-shadow: none;
}


.was-validated .form-control.is-invalid,
.form-control.is-invalid {
  background-image: none;
}

@media only screen and (min-width: 200px) and (max-width:480px) {

  .login_bgImg {
    background-image: url('../../../public/images/Login_page_1.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .login {
    background-color: rgba(255, 255, 255, 0.0);
    /* padding: 100px 100px; */
    /* width: 510px; */
    height: 500px;
    border-radius: 64px;
    width: 71%;
    /* max-width: 510px; */
    max-width: 460px;
    text-align: center;
    /* height: 80%;  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    position: relative;
  }

  .logo_image {
    position: absolute;
    top: 44px;
    left: 50%;
    transform: translateX(-50%);
    width: 103%;
    max-width: 289px;
    height: auto;
  }
}

@media only screen and (min-width: 481px) and (max-width:1024px) {
  .login_bgImg {
    background-image: url('../../../public/images/Login_page_1.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .login {
    background-color: rgba(255, 255, 255, 0.0);
    height: 500px;
    border-radius: 64px;
    width: 71%;
    max-width: 460px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    position: relative;
  }

  .logo_image {
    position: absolute;
    top: 29px;
    left: 50%;
    transform: translateX(-50%);
    width: 103%;
    max-width: 289px;
    height: auto;
  }
}

@media only screen and (min-width: 1025px) and (max-width:1300px) {
  .login_bgImg {

    background-image: url('../../../public/images/Login_page_1.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  
  
  }
  
  .login {
    background-color: rgba(255, 255, 255, 0.0);
    /* padding: 100px 100px; */
    /* width: 510px; */
    height: 500px;
    border-radius: 64px;
    width: 16%;
    /* max-width: 510px; */
    max-width: 460px;
    text-align: center;
    /* height: 80%;  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 9%;
    position: relative;
  }
  
  .logo_image {
    /* width: 289px;
    height: 114px;  */
    position: absolute;
    top: 60px;
    /* Adjust the top position to move it to the top of the login box */
    left: 50%;
    /* Center the image horizontally */
    transform: translateX(-50%);
    width: 100%;
    max-width: 289px;
    height: auto;
  }
  
}
