.search_button{
    display: flex;
    color: white;
    width: 120px;
    height: 40px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;  
    font-family: Montserrat;
    font-weight: 600;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 7px;
    border-color: #006AB0;
    box-shadow: none;
    background: #006AB0;
    font-size: 13px;
}

.search_button>p{
padding-top: 15px;
font-weight: 600;

}

.search_button p .search_icon  {
font-size: 15px; 
}

.search_button:hover{
background: #015994;

}