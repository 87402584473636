.EnquiryEntry {
  color: #4F4F4F;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* margin-left: -10%; */
}

.positive {
  color: green;
}

.select-dropdown {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  width: 100%;
}

/* .enquiryEntryRow {
  margin-top: 2%;
} */
/* .enquiryEntryRoww {
  margin-bottom: 2%;
} */

.react-select__single-value {
  color: green !important;
  /* Adjust the color based on your requirement */
}

.react-select__option {
  color: red !important;
  /* Adjust the color based on your requirement */
}

/* .enquiryEntryDate {
  display: flex;
  width: 248px;
  height: 42px;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #A7A7A7;
  background: #FFF;
  color: #A7A7A7;
} */

.enquiryEntryInput {
  /* color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 248px; */
}

.enquiryEntryContainer {
  width: 200%;
}

.custom-select {
  position: relative;
}

/* .rowCss {
  margin-bottom: 20px;
} */

/* .studentAddress {
  width: "400px";
  rows: "1";
} */

@media only screen and (min-width: 320px) and (max-width:480px) {
  .EnquiryEntry {
    font-size: larger;
    margin: 0px 0px 10px -1px;
  }
  
  /* .studentAddress {
    width: 111%;
    height: -96%;
    margin-top: -10%;
  } */

  /* .rowCss {
    margin-bottom: 0px;
  } */

  .enquiryEntryStudent {
    margin-bottom: 10%;
  }

  .enquiryEntryRow {
    margin-top: 2%;
  }

  .enquiryEntryContainer {
    width: 100%;
    height: 150%;
    margin-top: -3%;
  }

  .rows {
    margin-left: -10%;
  }

  .enquiryEntryDate {
    /* width: 55vw; */
    /* margin-bottom: -15%; */
    max-width: 100%;
  }

  select.dropdown-style.form-select {
    margin-bottom: -5%;
    width: 55vw;
  }

  .enquiryEntryInput {
    width: 248px;
    /* padding: 16px; */
    align-items: center;
    border-radius: 4px;
    margin-left: -10%;
  }

  .row>* {
    width: none;
    /* margin-bottom: 5%; */
  }

}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .EnquiryEntry {
    font-size: x-large;
    margin-left: 12%;
  }

  .enquiryEntryContainer {
    width: 80%;
    height: 160%;
    margin-top: -3%;
  }

  .rows {
    margin-left: -25%;
    width: 150%;
    font-size: x-large;
    /* padding: -10%; */
  }

  /* .rowCss {
    margin-bottom: 0px;
  } */

  .row>* {
    width: none;
    margin-bottom: 5%;
  }

  .input-style {
    margin-bottom: 1%;
    margin-top: 3%;
    width: 390px;
  }

  .enquiryEntryDropdown {
    margin-top: 3%;
    margin-bottom: -5%;
  }

  .enquiryEntryDate {
    width: 371px;
    margin-bottom: 1%;
  }

  select.dropdown-style.form-select {
    margin-bottom: -5%;
    width: 390px;
    margin-bottom: 5%;
  }

  .row>* {
    width: none;
  }

 
}

@media only screen and (min-width: 768px) and (max-width: 819px) {
  .EnquiryEntry {
    font-size: x-large;
    margin-left: 25%;
  }
  /* .rowCss {
    margin-bottom: 2px;
  } */


  .enquiryEntryContainer {
    width: 75%;
    height: 800px;
    margin-top: -3%;
  }

  .row>* {
    width: none;
    margin-bottom: 4%;
  }
  .rows {
    margin-left: -25%;
    width: 150%;
    font-size: x-large;
    /* padding: -10%; */
  }

  .input-style {
    margin-bottom: -2%;
    margin-top: 10%;
  }

  .enquiryEntryDropdown {
    margin-top: 10%;
    margin-bottom: -6%;
  }

  .enquiryDate {
    font-size: 17px;
    margin-top: -5%;
    margin-bottom: -9%;
  }

  .row>* {
    width: none;
  }


}

@media only screen and (min-width: 820px) and (max-width: 911px) {
  .EnquiryEntry {
    font-size: x-large;
    margin-left: 29%;
  }

  .enquiryEntryContainer {
    width: 75%;
    height: 800px;
    margin-top: -3%;
  }
  .row>* {
    width: none;
    margin-bottom: 5%;
  }

  /* .rowCss {
    margin-bottom: 2px;
  } */

  .rows {
    margin-left: -25%;
    width: 150%;
    font-size: x-large;
  }

  .input-style {
    margin-bottom: -2%;
    margin-top: 10%;
  }

  .enquiryEntryDropdown {
    margin-top: 10%;
    margin-bottom: -6%;
  }

  .enquiryDate {
    font-size: 17px;
    margin-top: -5%;
    margin-bottom: -9%;
  }

  .row>* {
    width: none;
  }

 
}

@media only screen and (min-width: 912px) and (max-width: 1023px) {
  .EnquiryEntry {
    font-size: x-large;
    margin-left: 1%;
  }

  /* .rowCss {
    margin-bottom: -3px;
  } */

  .row>* {
    width: none;
    margin-bottom: 2%;
  }

  .enquiryEntryContainer {
    width: 70%;
    height: 800px;
    margin-top: -1%;
    margin-bottom: 10px;
  }

  .rows {
    margin-left: -20%;
    width: 150%;
    font-size: x-large;
    /* padding: -10%; */
  }

  .input-style {
    margin-bottom: -2%;
    margin-top: 10%;
  }

  .enquiryEntryDropdown {
    margin-top: 10%;
    margin-bottom: -6%;
  }

  .row>* {
    width: none;
  }

}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .EnquiryEntry {
    font-size: x-large;
    margin-left: 0%;

  }

  .enquiryEntryContainer {
    width: 70%;
    height: 800px;
    margin-top: -1%;
    margin-bottom: 10px;
  }

  .enquiryEntryDate {
    width: 147px;
    margin-top: 0%;
  }

  .rows {
    margin-left: -75%;
    width: 250%;
    font-size: x-large;
    /* padding: -10%; */
  }

  .input-style {
    margin-bottom: -2%;
    margin-top: 10%;
  }

  select.dropdown-style.form-select {
    margin-top: 10%;
    width: 248px;
  }

  .enquiryEntryDropdown {
    margin-top: 10%;
    margin-bottom: -6%;
  }

  .row>* {
    width: none;
  }

}

@media only screen and (min-width: 1201px) and (max-width: 1280px) {
  .EnquiryEntry {
    font-size: x-large;
    margin-left: 0%;
  }

  .enquiryEntryContainer {
    width: 70%;
    height: 800px;
    margin-top: -1%;
    margin-bottom: 10px;
  }

  .enquiryEntryDate {
    width: 213px;
    margin-top: 1%;
  }

  select.dropdown-style.form-select {
    margin-top: 10%;
    width: 248px;
  }

  .rows {
    margin-left: -50%;
    width: 200%;
    font-size: x-large;
    /* padding: -10%; */
  }

  .input-style {
    margin-bottom: -2%;
    margin-top: 10%;
  }

  .enquiryEntryDropdown {
    margin-top: 10%;
    margin-bottom: -6%;
  }

  .row>* {
    width: none;
  }

}