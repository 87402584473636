.gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin-top: 20px;
}

.gallery-item {
    position: relative;
    width: 180px;
    height: 210px;
    cursor: pointer;
}

.toolbar-wrapper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}



.toolbar-wrapper {
    position: fixed;
    bottom: 32px;
    left: 50%;
    padding: 0px 24px;
    color: #fff;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
    padding: 12px;
    cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
    opacity: 0.3;
}

.toolbar-wrapper .anticon svg {
    fill: white !important; /* Set the fill color for the icons (e.g., red) */
}

.imageset {
    margin-left: 10px;
    margin-top: 8px;
}

.imagesize{
    width:220 !important;
    height:210 !important;
}


/* Media Queries */
@media screen and (max-width: 767px) {
    .imageset {
        /* Adjust styles for mobile devices */
        margin-left: 5px; /* Example adjustment */
        margin-top: 5px; /* Example adjustment */
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .imageset {
        /* Adjust styles for tablets */
        margin-left: 8px; /* Example adjustment */
        margin-top: 6px; /* Example adjustment */
    }
}

/* You can add more media queries for other screen sizes as needed */
