div.css-9y6gie-control{
    border: 1px solid #ccc;
    
}

div.css-13cymwt-control{
    border: 1px solid #ccc;
    height:2rem;
    
}


.search-dropdown{
    width: 100%;
    /* padding: 10px;
    border: 1px solid #ccc; */
   /* border-color: #A7A7A7; */
    border-radius: 5px;
    width: 100%;
    color: #A7A7A7 !important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 2;
}
.select-placeholder-text{
 
    color: #A7A7A7 !important;

}


@media (min-width: 0px) and (max-width: 480px) {
    .search-dropdown{
        /* margin-bottom: 25px; */
        font-size: 11px;
        /* padding: 0px; */
        /* height: 12px; */
    }

    .select-placeholder-text{
        font-size: 11px;

    }
    div.css-9y6gie-control{
        height: 0.1rem;
        font-size: 11px;
    }
    /* div.css-9y6gie-control::after{

        font-size: 11px;
    } */
    svg.css-tj5bde-Svg{
        height: 13px;
    }


}

@media (min-width: 481px) and (max-width: 576px) {
    .search-dropdown{
        /* margin-bottom: 25px; */
        /* height: 0.1rem; */
        
        /* height: 12px; */
    }
    div.css-9y6gie-control{
        /* height: 0.1rem; */
        font-size: 13px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .search-dropdown{
        /* margin-bottom: 25px; */
        /* height: 0.1rem; */
        
        /* height: 12px; */
    }

}



/* boxShadow: "none",
color: "#A7A7A7",
display: "flex",
// width: "248px",
// width: "18.38vw",
height: "42px",
color: "#5E6366",
fontFamily: "Montserrat",
fontSize: "14px",
fontStyle: "normal",
fontWeight: "500",
padding: "-0.625rem 0.75rem 0.375rem 0.75rem",
flexDirection: "row",
alignItems: "center",
lineHeight: "normal",
gap: "10px",
alignSelf: "stretch",
backgroundColor: "#FFF",
borderRadius: "4px",
border: "0.5px solid #A7A7A7", */