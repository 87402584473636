.EnquiryFollowUp {
    color: #4F4F4F;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -10px;
}

.Card1 {
    background-color: rgba(255, 255, 255, 0.561);
    backdrop-filter: blur(2px);
    height: 90vh;
    width: 90%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 2%;
}


.heading{
    font-family: poppins; 
    margin-left: -5%;
    height: 10%;
}

.EnquiryList table {
    width: 100%;
    /* Make the table take up the full width of its container */
    border-collapse: collapse;
    /* Collapse borders between table cells */
    /* border: 1px solid #ddd; */
    height: 10%;
    /* Add a border around the table */
}

.EnquiryList table th,
.EnquiryList table td {
    padding: 100px;
    /* Add padding to table cells */
    text-align: center;
    /* Center-align text in cells */
    /* border: 1px solid #ddd; */
    /* Add borders to table cells */
}

.EnquiryList table th {
    /* background-color: #f2f2f2; */
    /* Gray background for table header cells */
    font-weight: bold;
    /* Make the header text bold */
}

/* Optionally, you can style alternating rows with a different background color */
.EnquiryList table tr:nth-child(even) {
    /* background-color: #f9f9f9; */
    /* Light gray background for even rows */
}



/* Styles for smaller screens (e.g., tablets) */
@media (max-width: 768px) {
    .EnquiryList {
        font-size: 14px;
    }

    /* .container2 {
        padding: 10px;
    } */
}

/* Styles for even smaller screens (e.g., mobile phones) */
@media (max-width: 576px) {
    .EnquiryList {
        font-size: 12px;
    }
}