/* SchoolKidGrid.css */

/* Style for the container */
/* Your CSS file or style block */
.headers1{
  color: #4F4F4F;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 39px;
  width: auto;
  /* margin-left: -4px;
  margin-bottom:-30px; */
 
  
}

/* .form-control {
display: flex;
width: 248px;
height: 42px;
padding: 16px;
align-items: center;
border-radius: 4px;
border: 0.5px solid #A7A7A7;
background: #FFF;

} */
select.dropdown.form-select{
  display: flex;
  width: 248px;
  height: 42px;
  /* padding: 16px; */
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #A7A7A7;
  /* background: #FFF; */
}




.SelectDropdown input::placeholder {
  margin: "-8px";
}

.lable {
  color: black
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position:absolute;
  right: 35px;
  bottom: 77px;
 
  /* border-radius: 24px, */
}

.layout1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position:relative;
  right: 35px;
  bottom: 77px;
  margin-top: 140px;
  margin-left: 80px;
  padding-top: 10px;
}

.layout3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position:relative;
  right: 35px;
  bottom: 77px;
  margin-top: 140px;
  margin-left: 30px;
  padding-top: 10px;
}

@media (min-width: 200px) and (max-width: 480px) {
  .layout1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
  position:relative;
  right: 35px;
  bottom: 77px;
  margin-top: 140px;
  margin-left: 120px;
  padding-top: 10px;
  }

  .layout3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    position:relative;
    right: 35px;
    bottom: 77px;
    margin-top: 140px;
    margin-left: 30px;
    padding-top: 10px;
  }
}



@media (min-width: 481px) and (max-width: 576px) {
  .layout1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position:relative;
  right: 35px;
  bottom: 77px;
  margin-top: 140px;
  margin-left: 90px;
  padding-top: 10px;
  }

  .layout3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    position:relative;
    right: 35px;
    bottom: 77px;
    margin-top: 140px;
    margin-left: 30px;
    padding-top: 10px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .layout1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position:relative;
  right: 35px;
  bottom: 77px;
  margin-top: 140px;
  margin-left: 60px;
  padding-top: 10px;
  }

  .layout3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    position:relative;
    right: 35px;
    bottom: 77px;
    margin-top: 140px;
    margin-left: 30px;
    padding-top: 10px;
  }
}

@media (min-width: 769px) and (max-width: 992px){
  .layout1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .layout3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    position:relative;
    right: 35px;
    bottom: 77px;
    margin-top: 140px;
    margin-left: 30px;
    padding-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1200px){
  .layout1{
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position:relative;
  right: 35px;
  bottom: 77px;
  margin-top: 140px;
  margin-left: 50px;
  padding-top: 10px;
  }

  .layout3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    position:relative;
    right: 35px;
    bottom: 77px;
    margin-top: 140px;
    margin-left: 30px;
    padding-top: 10px;
  }
}

.layout2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position:absolute;
  /* right: 35px; */
  bottom: 18px;
  margin-top: 140px;
  margin-left: 40px;
  margin-bottom: 10px;
 
}

.title {
  color: #636363;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: auto;
  height: auto;
  
 
}
.title1{
color: #636363;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.container {
  /* background-image: url("../../../public/images/Rectangle5.jpg"); */
  margin-top: 20px;
  border-radius: 10px;
}

/* Style for the card */
.custom-card {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.custom-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style for the form inputs */
/* .form-control {
  margin-bottom: 10px;
} */

/* Style for the Add Kid button */
.btn-primary {
  margin-top: 10px;
}

/* Style for the card titles */
.card-title {
  font-weight: bold;
  font-size: 1.2rem;
}


/* Style for headings within the card */
.card h5 {
  margin-top: 15px;
}

/* Style for card images */
.card-img-top {
  max-height: 200px;
  object-fit: cover;
}

/* Style for card text */
.card-text p {
  margin: 5px 0;
}

/* Style for the School Kids Information heading */
h2 {
  margin-top: 20px;
  font-size: 1.5rem;
}

/* SchoolKidGrid.css */

.mtop1 {
  margin-top: 50px;
}

.mtop2 {
  margin-top: 80px;

}
.mtop3{
  margin-top: 28px;
}
.mtop4{
  margin-top:10px;
}

@media only screen and (max-width: 480px) {
  .mtop2 {
    margin-top: 10px;
  
  }
 
}

/* iPads and Tablets */
@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .mtop2 {
    margin-top: 0px;
  
  }
}

.button {
  padding: 2rem;
}

@media (max-width: 767px) {

  /* Add your CSS styles for mobile devices here */
  /* .mt1 {
    margin-top: 0px;
  }

  .mt2 {
    margin-top: 0px;
  } */
}








.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Align items to the bottom of the container */


}

.fcontrol {
  display: flex;
width: 248px;
height: 42px;
padding: 16px;
align-items: center;
border-radius: 4px;
border: 0.5px solid #A7A7A7;
background: #FFF;

}
/* input.inputDate-figma.form-control{
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

.fcontrol1 {
  display: flex;
width: 248px;
height: 42px;
/* padding: 16px; */
align-items: center;
border-radius: 4px;
border: 0.5px solid #A7A7A7;
/* background: #FFF; */

}
select.dropdown.form-select{
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
input.fcontrol.form-control::placeholder{
  color: #A7A7A7;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
input.fcontrol.form-control{
  color: #A7A7A7;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.css-qbdosj-Input{
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}
input#react-select-3-input{
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}
.malebutton{
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}
.css-1u9des2-indicatorSeparator {
  /* -webkit-align-self: stretch; */
  /* -ms-flex-item-align: stretch; */
  /* align-self: stretch; */
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}


.lableName{
  color: #636363;
font-family: Kumbh Sans;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.malebutton{
  color: #444;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 123.077% */
}

.uploadDoc {
  position: relative;
  display: flex;
  width: 210px;
  height: 38px;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (min-width: 200px) and (max-width: 480px) {
  .uploadDoc{
      width:100%; /* Adjust the width for screens between 200px and 480px */
      height: 5vh;
      font-size:11px;

  }
}



@media (min-width: 481px) and (max-width: 576px) {
  .uploadDoc{
      width: 100%; /* Adjust the width for screens wider than 576px */
      height: 6vh;
      font-size:13px;
      
      /* line-height:1px; */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .uploadDoc{
      width: 100%; /* Adjust the width for screens wider than 768px */
   
      /* line-height:3px; */
  }
}

@media (min-width: 769px) and (max-width: 992px){
  .uploadDoc{
  
      width: 100%; /* Adjust the width for screens wider than 992px */
  }
}

@media (min-width: 992px) and (max-width: 1200px){
  .uploadDoc{
      width: 100%; /* Adjust the width for screens wider than 1200px */
      /* height: 38px; */
  }
}