.EnquiryList {
    font-family: Poppins;
    font-size: 18px;
}

.container2 {
    padding: 20px;
}

.EnquiryTable {
    width: 135%;
    border-collapse: separate;
    border-spacing: 20px;
    margin: 0px 0px 0px -190px;
}

.enquiryView {
    color: #4F4F4F;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -10px;
}

.horizontal-line-row td {
    border-top: 1px solid #dddddd;
}

.enquiryItems tr {
    border-left: 3px solid #000;
    /* Change the border color and width to your preference */
    border-right: -10px;
    /* Remove the right border */
    border-top: 0;
    /* Remove the top border */
    border-bottom: 0;
    /* Remove the bottom border */
}

div.ant-card-body {
    padding: -10px;
}

.pagination-css .MuiPaginationItem-page.Mui-selected {
    color: blue;
}