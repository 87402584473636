.FeeQuoteContainer{
    width: 200%;
    height: 500px;
}

.FeeQuoteHeading{
    margin-left: 44%;
    color: #4F4F4F;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}