/* .Description_field{
    width:18.38vw;
    
}*/

.Description_field::placeholder{
    color: #A7A7A7 !important;
} 

.Description_field {
    width: 100%;
    padding: 11px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    resize: none; /* Disable textarea resizing */
    min-height: 5.7rem;
    overflow-y: hidden;
}
.form-control:focus{
    border-color: #4297e6;
    box-shadow: none;
  /* background-color: transparent; */
  }

  .text-right{
    font-size: 10px;
    color:#A7A7A7;
  }



@media (min-width: 200px) and (max-width: 480px) {
    .Description_field {
        width:100%; /* Adjust the width for screens between 200px and 480px */
        height: 2.4rem;
        font-size:11px;

    }
}



@media (min-width: 481px) and (max-width: 576px) {
    .Description_field {
        width: 100%; /* Adjust the width for screens wider than 576px */
        height: 6vh;
        font-size:13px;
        
        /* line-height:1px; */
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .Description_field {
        width: 100%; /* Adjust the width for screens wider than 768px */
     
        /* line-height:3px; */
    }
}

@media (min-width: 769px) and (max-width: 992px){
    .Description_field {
    
        width: 100%; /* Adjust the width for screens wider than 992px */
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .Description_field {
        width: 100%; /* Adjust the width for screens wider than 1200px */
    }
}
