.franchiseEntryHeading {
    margin-left: -10%;
    color:#4F4F4F;
    font-family: montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.franchiseContainer{
    width: 200%;
/*     height: 500px; */
}
/* .rc-text-field{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    
} */

/* .rowCss {
    margin-bottom: 20px;
  } */

