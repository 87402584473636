/* .App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
  /* Hide spinners in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.totals-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.total-item {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 48px;
}

.total-item0 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: -1px;
}

.total-item1 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 65px;
}

.total-item2 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 64px;
}

.total-item3 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 77px;
}

.total-item4 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 81px;
}

.total-item5 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 76px;
}

.total-item6 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 58px;
}

.total-item7 {
  padding: 0px 8px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  max-width: 53px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 55px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  margin: 10%;
}

.category-header {
  background-color: #f0f0f0; /* Light gray or any background color for category headers */
  border-bottom: 2px solid #ddd; /* Adds a border below the header */
}

/* Style for category item rows */
.category-item {
  background-color: white; /* Background color for items */
  border-bottom: 1px solid #ddd; /* Adds a border below each item for separation */
  padding: 10px 0; /* Adds vertical padding for spacing */
}

th {
  background-color: #f2f2f2;
}

.highlight-yellow {
  background-color: #DFE88F;
}

.highlight-blue {
  background-color: #CCECFC;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.active {
  background-color: #006AB0;
  color: white;
}

.one-line {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.long-address {
  max-width: 300px;
  /* Adjust this value as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rowCss {
  margin-bottom: 20px;
}

body {
  background-color: white;
}

.error-message {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.7rem;
  color: red;
}

.clear-button-figma-div {
  display: flex;
  width: 130px;
  height: 38px;
  padding: 17px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--primary, #006AB0);
  color: var(--primary, #006AB0);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  margin: 0px 10px;
}

.clear-button-figma-div:hover {
  background-color: var(--primary, #e0e2ec);
  border-radius: 7px;
}

.clear-button {
  display: flex;
  width: 60px;
  height: 28px;
  padding: 6px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--primary, #006AB0);
  color: var(--primary, #006AB0);
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 700;
  margin: 4px 10px;
}

.clear-button:hover {
  background-color: var(--primary, #e0e2ec);
  border-radius: 7px;
}

.save-button-figma-div {
  display: flex;
  color: white;
  width: 130px;
  height: 38px;
  padding: 17px 16px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border-color: #006AB0;
  box-shadow: none;
  background: #006AB0;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
}

td.MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium tablecell css-1ex1afd-MuiTableCell-root {
  margin-right: 500px;
}

.class_search {
  display: flex;
  color: white;
  width: 160px;
  height: 38px;
  padding: 17px 16px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 7px;
  border-color: #006AB0;
  box-shadow: none;
  background: #006AB0;
}

.class_search:hover {
  border-radius: 7px;
  background: #015085;
}

.searchCss {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (min-width: 577px) and (max-width: 768px) {
  .searchCss {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .searchCss {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .searchCss {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.save-button-figma-div:hover {
  border-radius: 7px;
  background: #015085;
}

.rowCss {
  margin-bottom: 20px;
}

.input-style {
  display: flex;
  width: 248px !important;
  height: 42px;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #A7A7A7;
  background: #FFF;
  /* margin-bottom:-25px; */

}

.input-style1 {
  display: flex;
  width: 248px !important;
  height: 42px;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #A7A7A7;
  background: #FFF;
  z-index: 9999 !important;
  /* margin-bottom:-25px; */

}

input.input-style.form-control::placeholder {
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


input.input-style.form-control {
  color: #A7A7A7;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 248px;
  border: 0.5px solid #A7A7A7;

}

.was-validated .form-control:invalid:not(:focus),
.was-validated .form-control:valid:not(:focus) {
  background-image: none;
  /* border-color: red; */
}

.was-validated .form-control:focus:invalid,
.was-validated .form-control:focus:valid {
  background-image: none;
  box-shadow: none;
}

/* .was-validated .form-check-input:valid~.form-check-label {
  color: none;
}
 .was-validated .form-check-input:valid~.form-check-label{
  color: none;
} */

/*  */

.was-validated .form-check-input:valid~.form-check-label {
  /* color: #4F4F4F; */
  --bs-form-valid-color: #4F4F4F;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.was-validated .form-check-input:valid:checked {
  /* background-color: #1677ff; */
  --bs-form-valid-color: #0d6efd;
}

select.dropdown-style.form-select {
  width: 308px;
  border: 0.5px solid #A7A7A7;
  color: #A7A7A7;
  height: 40px;
}

.form-check-input:valid {
  --bs-form-valid-border-color: #0d6efd;
  border-color: var(--bs-form-valid-border-color);
}

.content-header {

  color: #4F4F4F;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 1rem 0.1rem 2rem;

  /* margin-bottom:-30px;  */
  /* color: #4F4F4F;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 39px;
  width: 550px;
  margin: 1rem;
}

.sub-content-header {
  color: #4F4F4F;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 39px;
  width: 550px;
  margin-left: -4px;
  margin-bottom:-30px; */
}

/* .was-validated .form-control:invalid:not(:focus),
.was-validated .form-control:valid:not(:focus),
.was-validated .form-control:focus:invalid,
.was-validated .form-control:focus:valid,
.was-validated select:invalid:not(:focus),
.was-validated select:valid:not(:focus),
.was-validated select:focus:invalid,
.was-validated select:focus:valid {
  background-image: none;
  box-shadow: none;
} */

.was-validated .form-control:invalid:not(:focus),
.was-validated .form-control:valid:not(:focus),
.was-validated .form-control:focus:invalid,
.was-validated .form-control:focus:valid,
.was-validated select:invalid:not(:focus),
.was-validated select:valid:not(:focus),
.was-validated select:focus:invalid,
input.input-style.form-control.is-invalid,
textarea.Description_field.form-control.is-invalid,
.was-validated select:focus:valid {
  background-image: none;
  box-shadow: none;
}


.inputDate {
  display: flex;
  width: 248px;
  height: 42px;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #000000;
  background: #FFF;
}




.dropdown-style {
  display: flex;
  width: 248px;
  height: 42px;
  padding: 16px;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #A7A7A7;
  background: #FFF;
}

.label-style {
  color: #636363;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ant-card .ant-card-body {
  padding: 24px;
  border-radius: 0 0 8px 8px;
  /* max-height: calc(100vh - 50px);
    overflow-y: auto; */
}

/* .row-class{
  margin-top:25px;
} */

@media (min-width: 200px) and (max-width: 480px) {
  .content-header {
    font-size: 13px;
    margin: 1rem 0;
  }

  .clear-button-figma-div {

    width: 66px;
    height: 33px;
    padding: 15px 16px;
    font-size: 10px;
    line-height: normal;
    /* padding: 17px 16px;
  line-height: normal;
  margin: 0px 10px;   */
  }



  .save-button-figma-div {

    width: 66px;
    height: 33px;
    padding: 15px 16px;
    font-size: 10px;

    line-height: normal;
    margin: 0px;
    border-color: #006AB0;
    box-shadow: none;
    background: #006AB0;
  }

}


@media (min-width: 481px) and (max-width: 576px) {
  .content-header {
    font-size: 15px;
    margin: 1rem 0;

  }

  .clear-button-figma-div {

    width: 90px;
    height: 40px;

    font-size: 13px;
    /* padding: 17px 16px;
    line-height: normal;
    margin: 0px 10px;   */
  }

  .clear-button-figma-div:hover {
    background-color: var(--primary, #e0e2ec);
    font-size: 13px;
    width: 90px;
    height: 40px;
  }

  .save-button-figma-div {
    width: 90px;
    height: 40px;
    padding: 16px 16px;
    font-size: 13px;
    line-height: normal;
    margin: 0px 10px;
    border-color: #006AB0;
    box-shadow: none;
    background: #006AB0;
  }


  .save-button-figma-div:hover {
    background: #015994;
    font-size: 13px;
    width: 100px;
    height: 38px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .content-header {
    font-size: 18px;
    margin: 1rem 0;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .content-header {
    font-size: 22px;
    margin: 1rem 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .content-header {
    font-size: 22px;
    margin: 1rem 0;
  }
}


.heading {
  margin-left: 40%;
  color: #4F4F4F;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

div.ant-layout-sider-trigger {
  width: 274px;
}


.errorBorder {
  border: 1px solid red;
  border-radius: 4px;
}

:where(.css-2i2tap).ant-menu-submenu>.ant-menu {
  display: none;
}

.sub-text {
  font-size: 0.9em; /* Adjust size as needed */
  color: #666; /* Adjust color as needed */
}
