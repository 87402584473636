/* .parentProfileButtons{
    width:200px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-left: 50px;
}
.parentProfileButtons span{
    cursor: pointer;
}
.parentWrapper{
    
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    margin:20px;
    border-top:5px solid white;
    padding-top:5px;
    
}
.onlyParent{
    width:100%;
    height:300px;
    font-size:25px;
}
.info-wrapper{
    width:70%
}

.parent-keys{
    display:flex;
    justify-content: flex-start;
    font-size: smaller;
    color:#454545;
    width:70%;
}
.keys-wrapper{
    display:flex;
    justify-content: space-between;
    width:100%;
}
.parent-prop{
    color:#8c8787;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    width:30%;
}
.Pprofile,.Cprofile{
    border-bottom: 2px solid blue;
    color:#0052B4;
    font-family: montserrat;
    font-weight: bold;
    font-size: large;
}
.profileImg{
    border-radius: 15px;
}
.common{
    border-bottom: 2px transparent blue;
    color:#087FD2;
    font-weight: bold;
    font-size: medium;
}
.viewButtonDetails{
   margin-left: 700px;
}
.viewButtonDetails button{
    width:170px;
    border-radius: 6px;
    padding:6px;
    outline:none;
    border:2px solid beige;
    color:black;
    background-color: bisque;
}
.viewDetails{
    width:100%;
    display:flex;
    justify-content: space-between;
    padding:20px 120px 20px 70px ;
    font-size:25px;
}
.viewOne{
    width:30%;
}
.progressHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color:darkblue;
    text-transform: capitalize;
}
.progressDiv{
    width:250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width:999px) {
    .viewButtonDetails{
        margin-left: 10px;
    }
    .viewDetails{
        display: flex;
        flex-direction: column;
    }
    .progressDiv{
        display:flex;
        width:200px;
        justify-content: space-between;
    }
}
@media screen and (max-width:768px) {
    .parentWrapper{
        display:flex;
        flex-direction: column-reverse;
        height:fit-content;
        margin: none;
    }
    .info-wrapper{
        width:100%;
    }
    .keys-wrapper{
        display:flex;
        flex-direction: column;
    }
    .parent-prop, .parent-keys{
        width:100%;
    }
} */

.profile-wrapper {
    /* background-color:green; */
    /* overflow: scroll; */
    font-size: 1vw;
    padding: 10px;
}

.profile-buttons-wrapper {
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 30px;
    height: max-content;
    font-weight: 100;
    font-family: 'math';
    color: #575a5c;
    font-size: large;
    margin-bottom: 20px;

}

.parent-btn,
.child-btn {
    /* border: 3px solid #006AB0; */
    /* Border color */
    border-radius: 10px;
    /* Adjust this value for the desired curve */
    padding: 4px;
    /* Adjust this value for the desired inside space */

    /* text-decoration-line: underline; */
    text-decoration-color: #006AB0;
    color: #006AB0;
    font-size: 1.6vw;
    cursor: pointer;
    font-family: math;
    margin-bottom: 10px;
}

.common-btn-styles {
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.parent-data-wrapper {
    display: flex;
    justify-content: space-between;
}

.each-prop {
    display: flex;
    align-items: center;
    font-family: math;
    font-size: large;
}

.child-image {
    width: 100px;
    height: auto;
    border-radius: 50%;
    margin-right: 10px;
}

.each-key {
    color: #6d6d6d;
    width: 18%;
    font-family: revert;
    font-weight: 700;
}

.view-key {
    color: #6d6d6d;
    width: 18%;
    font-family: revert;
    font-weight: 700;
}

.each-colon {
    width: 2%
}

.each-value {
    color: #282727;
    width: 70%;
    padding-left: 10px;
    font-family: poppins;
    font-size: 20px;
    font-weight: 600;
}

.view-value {
    color: #282727;
    width: 68%;
    padding-left: 10px;
    /* font-weight: 400; */
    font-family: poppins;
    font-size: 21px;
}

.parent-data {
    width: 90%;
}

.profile-data {
    width: 20%;

}

.profileImg {
    width: 100px;
    border-radius: 50%;
    height: 100px;
}

.childrenDataWrapper {
    margin-bottom: 20px;
    border-bottom: 1px solid black;
}

.viewButtonDetails {
    margin: 10px 0 10px 30vw;

}

.viewButtonDetails button {
    width: fit-content;
    border-radius: 6px;
    padding: 6px;
    outline: none;
    border: 2px solid #767676;
    color: white;
    background-color: #767676;
}

.viewDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 120px 20px 70px;
    font-size: 25px;
}

.view-data-wrapper {
    display: flex;
}

.view-data {
    width: 50%;

}

/* .viewOne{
     width:30%;
 } */
.progressWrapper {
    width: 100%;

    /* display: flex;
    align-items: center;
    justify-content: space-evenly; */
}

.progressHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: darkblue;
    text-transform: capitalize;
}

.progressDiv {
    width: 500px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}




@media screen and (max-width:768px) {
    .profile-wrapper {
        font-size: 3vw;
        overflow-x: scroll;
    }

    .parent-btn,
    .child-btn {
        font-size: 3.5vw;
    }

    .parent-data-wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 20px;
    }

    .profile-buttons-wrapper {
        margin: 0
    }

    .parent-btn,
    .child-btn {
        margin: 2px;

    }

    .profile-data {
        width: 100%;
        text-align: center;
    }

    .view-data-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .viewButtonDetails {
        margin-left: 0px;
    }

    .viewButtonDetails button {
        width: fit-content
    }

    .view-data {
        width: 100%;
    }

    .progressDiv {
        width: 250px
    }
}




@media (max-width: 576px) {
    .ant-avatar {
      width: 100px !important;
      height: 100px !important;
    }
  
    .ant-typography {
      font-size: 14px !important;
    }
  }