
.upload_maindiv{
    position: relative;
    display: flex;
    width: 100%;
    height: 42px;
    padding: 16px;
    align-items: center ;
    border-radius: 4px;
    border: 0.5px solid #A7A7A7;
    background: #FFF;
    color: #A7A7A7;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.upload_layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    position:relative;
    right: 35px;
    bottom: 77px;
    margin-top: 140px;
    margin-left: 60px;
    padding-top: 10px;
  }

@media (min-width: 200px) and (max-width: 480px) {
    .upload_maindiv{
        width:100%; /* Adjust the width for screens between 200px and 480px */
        height: 23px;
        font-size:11px;

    }
}



@media (min-width: 481px) and (max-width: 576px) {
    .textInput_Field{
        width: 100%; /* Adjust the width for screens wider than 576px */
        height: 6vh;
        font-size:13px;
        
        /* line-height:1px; */
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .textInput_Field{
        width: 100%; /* Adjust the width for screens wider than 768px */
     
        /* line-height:3px; */
    }
}

@media (min-width: 769px) and (max-width: 992px){
    .textInput_Field{
    
        width: 100%; /* Adjust the width for screens wider than 992px */
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .textInput_Field{
        width: 100%; /* Adjust the width for screens wider than 1200px */
    }
}


.uploadImg {
    position: relative;
    display: flex;
    width: 208px;
    height: 38px;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  

  @media (min-width: 200px) and (max-width: 480px) {
    .uploadImg{
        width:100%; /* Adjust the width for screens between 200px and 480px */
        height: 5vh;
        font-size:11px;

    }
}



@media (min-width: 481px) and (max-width: 576px) {
    .uploadImg{
        width: 100%; /* Adjust the width for screens wider than 576px */
        height: 6vh;
        font-size:13px;
        
        /* line-height:1px; */
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .uploadImg{
        width: 100%; /* Adjust the width for screens wider than 768px */
     
        /* line-height:3px; */
    }
}

@media (min-width: 769px) and (max-width: 992px){
    .uploadImg{
    
        width: 100%; /* Adjust the width for screens wider than 992px */
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .uploadImg{
        width: 100%; /* Adjust the width for screens wider than 1200px */
    }
}