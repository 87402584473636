.react-datepicker-wrapper{
  width: 100%;
}
.DatePicker_field{
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #A7A7A7;
  font-family: Montserrat!important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.DatePicker_field::placeholder{
  color: #A7A7A7;
}

.DatePicker_field.is-invalid {
  border-color: red; /* Change the border color to red */
}

.DatePicker_field.is-valid {
  border-color: #198754; /* Change the border color to green */
}



@media (min-width: 200px) and (max-width: 480px) {
  .DatePicker_field{
      width:100%; /* Adjust the width for screens between 200px and 480px */
      height: 5vh;
      font-size:11px;

  }
}



@media (min-width: 481px) and (max-width: 576px) {
  .DatePicker_field{
      width: 100%; /* Adjust the width for screens wider than 576px */
      height: 6vh;
      font-size:13px;
      
      /* line-height:1px; */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .DatePicker_field{
      width: 100%; /* Adjust the width for screens wider than 768px */
   
      /* line-height:3px; */
  }
}

@media (min-width: 769px) and (max-width: 992px){
  .DatePicker_field{
  
      width: 100%; /* Adjust the width for screens wider than 992px */
  }
}

@media (min-width: 992px) and (max-width: 1200px){
  .DatePicker_field{
      width: 100%; /* Adjust the width for screens wider than 1200px */
  }
}
