/* body{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color:rgb(172, 189, 183);
    color:cadetblue;
}
.formField{
    width:20rem;
    padding:10px;
    display:inline-block;
}
.address{
    width:39rem;
    margin:auto;
}
.btn{
    margin-top:20px;
    width:10rem;
    
    
     background-color:blue; 
    
}
.hbtn{
    height:45px;
    width:150px;
    background-color:whitesmoke;
    border:2px solid transparent;
    color:black;
    border-radius: 8px 0 0 8px;
    display:flex;
    justify-content: space-around;
    align-items: center;
    
}
.himg{
    height:50px;
    width:50px;
}
.hinput{
    outline:none;
    border:1px solid whitesmoke;
    border-left: none;
    background-color:azure;
    width:500px;
    padding-left: 20px;
    border-radius: 0 8px 8px 0;
}
.search{
    background-color: azure;
    border: 1px solid whitesmoke;
    border-right: none;
    display:flex;
    align-items: center;
    padding-left: 20px;
}
th,td{
    padding: 10px;
    border-bottom: 1px solid red;
}
.ebtn{
    outline:none;
    width:70px;
    color:red;
    border:2px solid green;
    border-radius: 5px;
} */
.h-card{
    display: flex;
    height:5rem;
    margin:1rem;
    padding:15px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.60);
    /* backdrop-filter: blur(2px); */
}
.h-main-div{
    /* background-color: blue; */
    /* height:2rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%
    
}
.drop-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:50px;
    width:500px;
}
.dropdown button{
    border-radius: 5px 0 0 5px;
    width:100px;
    height:49px;
    margin-left:10px;
    margin-bottom: 20px;
    background-color: white;
    color:black;
    border: 2px solid white;
}

.h-s-img{
    height:50px;
    border-radius:0 ;
}
.h-search{
    height:50px;
    width:400px;
}
.input{
    margin-top: 10px;
}
.second-div{
    display:flex;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */
    width:130px;
    /* margin-right:30px ; */
    position: absolute;
    right:0;
}

sup{
    color:red;
    font-size: small;
}
.h-img{
    height:60px;
    width:60px;
    border-radius: 50%;
}

.content-popup{
width:9rem;
height: 4rem;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-popover .ant-popover-inner {
    background-color: #ffffff;
    background-clip: padding-box;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 0px;
}

.changepswd{
    padding: 5px;
}
.logout{
    padding: 5px;  
}

.changepswd:hover{
    background-color:#e4dfe4 ;
    border-radius: 6px 6px 0px 0px;
    cursor: pointer ;
}
.logout:hover{
    background-color:#e4dfe4 ;
    border-radius: 0px 0px 6px 6px;
    cursor: pointer ;
}

/* .drop{
    width:500px
} */
/* #dropdown-basic{
    width:"150px"
} */