.table-Maindiv {
    /* background: #FFF; */
    padding: 10px;
    margin-top: 15px;

}

.css-aa5kfg-MuiTableCell-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    /* background-color:transparent!important; */
    color: #464E5F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.table-container {

    /* height: 89%; */
    overflow: none;
}

/* .tablehead {
    background-color: #E2E8F0;
    color: #464E5F;
        font-family: Montserrat !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-align: center !important;
        white-space: nowrap;
} */

.tablecell {
    padding: 8px !important;
    text-align: left !important;
    /* text-align: center !important; */
}

.tablecell2 {
    /* padding: 8px !important; 
     text-align: left !important; */
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;

}

.tablecell1 {
    /* padding-left: 140px !important; */
    text-align: center !important;
}

.css-1ex1afd-MuiTableCell-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 350px;
}

.pagination-css {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 0px 0px 0px;
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.Mui-selected {
    color: white;
    background-color: #112F6E;
}

path {
    color: #112F6E;
}

.Table-card {

    font-family: Open Sans;
    margin-top: 1%;
    padding: 1rem 1rem;
    border-radius: 5px;
    border: 1px solid #c2c4c2;
    width: 100%;
    /* height: 44rem; */
    background-color: white;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
}

.table-button {
    display: flex;
    color: white;
    width: 90px;
    height: 20px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    border-color: #006AB0;
    box-shadow: none;
    background: #006AB0;
}


.tablecell31 {
    border-bottom: 1px solid black !important;
    padding: 3px !important;
    text-align: left !important;
    /* width:200px; */

}


.tablecell3 {
    border-bottom: 1px solid black !important;
    padding: 3px !important;
    text-align: left;

    /* border-right: 1px solid black;  */
}

/* Optional: Add some padding to the cells for better spacing */
/* .tablecell3 {
    padding: 8px;
  } */

  
  .tablecell button {
    background-color: transparent; 
    color: black;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .tablecell button:hover {
    background-color: transparent; /* No background on hover */
  }
  
  .dots {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    line-height: 1; /* Ensure dots are closely stacked */
    margin-left: 50px;
  }
  
  /* .dots::before {
    content: '⋮'; 
  } */
  
  /* .options-container {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  
  .options-container button {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 8px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }
  
  .options-container button:hover {
    background-color: #f5f5f5;
  }
  
  .options-container button:focus {
    outline: none;
  } */
  
  .tablecells select {
    appearance: none; /* Remove default styling */
    background: white; /* Set background color */
    border: 1px solid #ccc; /* Set border color */
    border-radius: 4px; /* Match border radius */
    padding: 8px; /* Add some padding */
    font-size: 14px; /* Match font size */
    cursor: pointer; /* Change cursor to pointer */
    /* Add any additional styles to match your design */
  }
  
  /* Additional styles for the dropdown if needed */
  .tablecells select:focus {
    outline: none; /* Remove outline on focus */
    border-color: #007bff; /* Change border color on focus */
  }
  