
/* .add-teacher-r-mt{
    margin-top:20px;
}
.add-teacher-address{
    width:550px;
}
.add-teacher-input{
    display: flex;
    width: 230px;
    height: 40px;
}
.dropdown-arrangement{
    display:flex;
}
.add-teacher-btns{
    width:100px;
} */
.add-employee-error{
    position: relative;
    top:5px;
    color:red;
    /* text-transform:uppercase; */
    font-size: smaller;
}

.uploadFileStyle{
    position: relative;
    display: flex;
    width: 248px;
    height: 42px;
    padding: 16px;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid #A7A7A7;
    background: #FFF;
}
.errorBorder{
    border:1px solid red;
    border-radius: 4px;
}
.uploadDocInput{
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
}